import { useQuery } from '@tanstack/react-query';

import useSupabase from '../supabase';

export function useCurrentUserQuery(username: string, skip: boolean = false) {
  const client = useSupabase();
  const queryKey = ['user', username];

  const queryFn = async () => {
    return getUserById(client, username).then((result: any) => result.data);
  };

  return useQuery({ queryKey, queryFn, enabled: !skip });
}

export function getUserById(client: any, username: string) {
  return client
    .from('users')
    .select()
    .eq('username', username)
    .limit(1)
    .single()
    .throwOnError();
}
