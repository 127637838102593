import { useEffect } from 'react';

import { Box, Divider, Link, Typography } from '@mui/material';

import { useGetReferralStage } from '../../../api/useGetReferralStage';
import { LEARN_MORE_POINTS_PROGRAM } from '../../../constants/externalLinks';
import { APP_PATH } from '../../../constants/routings';
import useCurrentUserData from '../../../hooks/auth/useCurrentUserData';
import { REF_SEARCH_KEY } from '../../../hooks/useReferSearchValue';
import { formatMultiplier } from '../../../lib/formatMultiplier';
import { CountdownProvider } from '../../../providers/CountdownProvider';
import { RefCountdownSection } from '../../RefCountdownSection';
import { ModalContainer } from '../ModalContainer';

import { ShareModalBody } from './Content/ShareModalBody';
import { ListItem } from './ListItem';

export const REF_MODAL_SHOWN_KEY = 'REF_MODAL_SHOWN';

export interface ReferFriendsModalProps {
  multiplier: number | null;
}

export function ReferFriendsModal() {
  const { data: userData } = useCurrentUserData();

  const { data: refStage } = useGetReferralStage();

  const showMultiplier = refStage?.multiplier;

  const refLink = `${window.location.origin}${APP_PATH}?${REF_SEARCH_KEY}=${userData.referral_code}`;

  useEffect(() => {
    localStorage.setItem(REF_MODAL_SHOWN_KEY, 'true');
  }, []);

  return (
    <ModalContainer
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box display="flex" flexDirection="column">
        <CountdownProvider>
          <RefCountdownSection />
        </CountdownProvider>

        <Typography
          variant="h1"
          fontSize={{ xs: '20px', md: '24px' }}
          fontWeight="500"
          mt={{ xs: 4, md: 10 }}
        >
          Invite your friends and earn rewards
        </Typography>

        <Divider sx={{ my: { xs: 3, md: 6 } }} />

        <Box display="flex" flexDirection="column" gap={3} mb={5}>
          <Typography fontSize={{ xs: 18, md: 20 }} fontWeight={300}>
            How it works
          </Typography>

          <ListItem number={1}>
            Share your referral link with your friends
          </ListItem>
          <ListItem number={2}>Your friends sign up with your link</ListItem>

          {showMultiplier && (
            <ListItem number={3}>
              {'You get '}
              <Typography
                component="span"
                fontWeight={600}
                fontSize={{ xs: 12, md: 14 }}
              >
                {formatMultiplier(refStage?.multiplier)}
              </Typography>
              {' point boost commission for points they earn'}
            </ListItem>
          )}
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Typography sx={{ fontSize: { xs: '12px', md: '14px' } }}>
            -
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '12px', md: '14px' },
              fontWeight: 300,
              color: '#F0F5FA',
            }}
          >
            Click{' '}
            <Link
              href={LEARN_MORE_POINTS_PROGRAM}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ fontSize: { xs: '12px', md: '14px' } }}
            >
              here
            </Link>{' '}
            to learn more about the Altcoinist Points Program
          </Typography>
        </Box>

        <Divider sx={{ my: { xs: 3, md: 6 } }} />

        <ShareModalBody title="Your referral link" shareLink={refLink} />
      </Box>
    </ModalContainer>
  );
}
