import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';

import { TopUpWalletContent } from './Content/TopUpWalletContent';

export const TOPUP_WALLET_TITLE = 'Get ETH on Base to set up your pricing';
export const TOPUP_WALLET_DESCRIPTION =
  'Get some ETH on Base to cover gas when setting up your pricing.';

export function TopUpWalletModal() {
  const { hideModal } = useGlobalModal();

  const handleProceed = () => {
    hideModal();
  };

  return (
    <ModalContainer
      title={TOPUP_WALLET_TITLE}
      description={TOPUP_WALLET_DESCRIPTION}
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <TopUpWalletContent proceed={handleProceed} pt={10} />
    </ModalContainer>
  );
}
