import { SupabaseClient } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

import useSupabase from '../supabase';

export function useUserByWalletAddressQuery(walletAddress: string | null) {
  const client = useSupabase();
  const queryKey = ['user_by_wallet', walletAddress];

  const queryFn = async () => {
    return getUserByWalletAddress(client, walletAddress ?? '').then(
      (result: any) => result.data,
    );
  };

  return useQuery({ queryKey, queryFn, enabled: !!walletAddress });
}

export function getUserByWalletAddress(
  client: SupabaseClient<any, 'public', any>,
  walletAddress: string,
) {
  return client
    .from('users_view')
    .select('username, avatar, wallet_address')
    .eq('wallet_address', walletAddress)
    .single();
}
