import { Box, Divider, Link, TextField, Typography } from '@mui/material';

import { ModalContainer } from '../ModalContainer';
import { GET_CREATOR_PATH, PROFILE_PATH } from '../../../constants/routings';
import { CopyClipboardButton } from '../Buttons/CopyClipboardButton';
import useCurrentUserData from '../../../hooks/auth/useCurrentUserData';
import { CheckBgIcon } from '../../icons';
import { StepIndicator } from '../StepIndicator';

const instructions = [
  {
    title: 'Copy your community subscription link',
    description: 'It takes people to your subscription page.',
  },
  {
    title: 'Paste the link in your bio on your social media',
    description: 'Makes it easy for your followers to find it.',
  },
  {
    title: 'Share your link with your content',
    description: 'Keeps your community growing with new members.',
  },
];

export function CreatorOnboardingWelcomeModal() {
  const { data: userData, isLoading } = useCurrentUserData();

  const refLink = `${import.meta.env.VITE_PUBLIC_SITE_URL}${GET_CREATOR_PATH(
    userData?.username ?? '',
  )}`;

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <ModalContainer
      title={'You’re almost done!'}
      description={'Let’s start growing and monetizing your community.'}
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
      topLeftIcon={<CheckBgIcon />}
      onClose={handleClose}
    >
      <>
        <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', pt: 6 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {[1, 2, 3].map((step) => (
              <StepIndicator key={step} step={step} />
            ))}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            {instructions.map(({ title, description }, index) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                key={index}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    lineHeight: '20px',
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    color: '#9B9FA3',
                    fontSize: { xs: '12px', md: '14px' },
                    lineHeight: '17px',
                    fontWeight: 300,
                  }}
                >
                  {description}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Divider sx={{ my: 6 }} />
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: { xs: '14px', md: '16px' },
            lineHeight: '20px',
          }}
        >
          Your Subscription Link
        </Typography>
        <TextField
          type="text"
          sx={{
            pt: 3,
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputBase-input': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: '16px',
            },
          }}
          InputProps={{
            readOnly: true,
            sx: { height: { xs: '50px', md: '56px' } },
            endAdornment: (
              <CopyClipboardButton edge="end" textToCopy={refLink} />
            ),
          }}
          value={refLink}
          inputProps={{ title: refLink }}
        />
        <Typography
          sx={{ color: '#9B9FA3', fontSize: { xs: '14px', md: '16px' }, pt: 4 }}
        >
          You can always get this link from{' '}
          <Link rel="noopener noreferrer" href={PROFILE_PATH}>
            profile
          </Link>{' '}
          as well!
        </Typography>
      </>
    </ModalContainer>
  );
}
