import { Box, Button, Link, Typography } from '@mui/material';

import { LEARN_MORE_TELEGRAM_BOT_GUIDE_LINK } from '../../../constants/externalLinks';
import { TelegramQRIcon } from '../../icons';
import { ModalContainer } from '../ModalContainer';

function generateTelegramBotLink() {
  return `https://t.me/${import.meta.env.VITE_BOT_USERNAME}?start`;
}

export function ConnectTelegramModal() {
  const botLink = generateTelegramBotLink();

  const handleConnectTelegram = () => {
    window.open(botLink, '_blank');
  };

  return (
    <ModalContainer
      title="Connect Your Group Now!"
      description="Add the Altcoinist Assistant. This Bot will help you manage your community."
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box display="flex" flexDirection="column" gap={4} pt={6}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Typography>•</Typography>
          <Typography
            sx={{ color: "text.secondary", fontWeight: 300, fontSize: '14px' }}
          >
            Scan the QR code with your mobile device to continue. If you are on mobile, click &quot;Connect&quot; to continue.
          </Typography>
        </Box>
        <Typography
          sx={{ color: '#F0F5FA', fontWeight: 300, fontSize: '14px' }}
        >
          Click{' '}
          <Link
            href={LEARN_MORE_TELEGRAM_BOT_GUIDE_LINK}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ fontSize: '14px' }}
          >
            here
          </Link>{' '}
          for a full user guide that helps you with this step!
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
          pt={6}
        >
          <TelegramQRIcon />
        </Box>

        <Button
          variant="contained"
          size="medium"
          onClick={handleConnectTelegram}
          sx={{
            alignSelf: 'start', mt: 6, display: {
              xs: 'block',
              sm: 'block',
              md: 'none',
            },
          }}
        >
          Connect
        </Button>
      </Box>
    </ModalContainer>
  );
}
