import { Box, Button, Link, Typography, TypographyProps } from '@mui/material';

import {
  AUDIT_REPORT_LINK,
  LEARN_MORE_PULL_PAYMENT_TERMS_LINK,
  PAYMENT_COLLECTOR_CONTRACT_CODE_LINK,
} from '../../../constants/externalLinks';
import { HalbornLogo } from '../../icons';
import { ModalContainer } from '../ModalContainer';

const textStyle: TypographyProps = {
  fontSize: 16,
  fontWeight: 300,
};

export interface PullPaymentApprovalModalProps {
  onContinueClick: () => void;
}

export const PULL_PAYMENTS_MODAL_APPROVED_KEY = 'PULL_PAYMENTS_MODAL_APPROVED';

export function PullPaymentApprovalModal({
  onContinueClick,
}: PullPaymentApprovalModalProps) {
  const handleClick = () => {
    onContinueClick();
  };

  return (
    <ModalContainer
      title="Read This Before You Subscribe!"
      contentProps={{
        sx: {
          width: 'min(100%, 960px)',
          minHeight: { xs: '100vh', sm: 'auto' },
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxHeight={{ xs: 'calc(100vh - 96px)', sm: 'auto' }}
      >
        <Typography color="text.secondary" mt={{ xs: 3, sm: 2 }} {...textStyle}>
          Here’s what happens as you subscribe (all done in one batch
          transaction for you):
        </Typography>

        <Box sx={{ overflowY: 'auto' }}>
          <Box display="flex" gap={2} mt={{ xs: 5, sm: 6 }}>
            <Typography
              color="text.primary"
              {...textStyle}
              minWidth={10}
              textAlign="right"
            >
              1.
            </Typography>

            <Typography color="text.secondary" {...textStyle}>
              <Typography component="span" color="text.primary">
                ETH to wETH conversion:{' '}
              </Typography>
              Your ETH needed for the subscription will automatically be wrapped
              to wETH.
            </Typography>
          </Box>

          <Box display="flex" gap={2}>
            <Typography
              color="text.primary"
              {...textStyle}
              minWidth={10}
              textAlign="right"
            >
              2.
            </Typography>

            <Typography color="text.secondary" {...textStyle}>
              <Typography component="span" color="text.primary">
                Approve Subscription:{' '}
              </Typography>
              You’ll approve wETH needed for your subscription.
            </Typography>
          </Box>

          <Box display="flex" gap={2}>
            <Typography
              color="text.primary"
              {...textStyle}
              minWidth={10}
              textAlign="right"
            >
              3.
            </Typography>

            <Typography color="text.secondary" {...textStyle}>
              <Typography component="span" color="text.primary">
                Set Up Recurring Payments:{' '}
              </Typography>
              You’ll give a smart contract permission to handle your recurring
              payments. This is a one-time approval, and the contract is
              restricted to pulling ONLY the pre-approved amount for the
              subscription each month. Click{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                href={LEARN_MORE_PULL_PAYMENT_TERMS_LINK}
              >
                here
              </Link>{' '}
              to read more about our Pull Payments Terms of Use.
            </Typography>
          </Box>

          <Box display="flex" gap={2}>
            <Typography
              color="text.primary"
              {...textStyle}
              minWidth={10}
              textAlign="right"
            >
              4.
            </Typography>

            <Typography color="text.secondary" {...textStyle}>
              <Typography component="span" color="text.primary">
                Completion and Subscription:{' '}
              </Typography>
              Your subscription is done for you, quickly and effortlessly.
            </Typography>
          </Box>

          <Typography
            color="text.secondary"
            {...textStyle}
            mt={{ xs: 5, sm: 6 }}
          >
            <Typography component="span" color="text.primary">
              Security:{' '}
            </Typography>
            Our contracts are audited by Halborn. Check out the{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              href={AUDIT_REPORT_LINK}
            >
              Audit Report
            </Link>{' '}
            and our{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              href={PAYMENT_COLLECTOR_CONTRACT_CODE_LINK}
            >
              Payment Collector GitHub Repository
            </Link>{' '}
            .
          </Typography>
        </Box>

        <Typography color="text.primary" mt={10}>
          Click “Continue” to confirm you understand and proceed to the next
          step.
        </Typography>

        <Box
          display="flex"
          mt={{ xs: 5, sm: 6 }}
          gap={{ xs: 5, sm: 6 }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="center"
        >
          <Button
            onClick={handleClick}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            Continue
          </Button>

          <Box display="flex" alignItems="center" gap={2}>
            <Typography color="text.secondary">Audited by </Typography>
            <HalbornLogo />
          </Box>
        </Box>
      </Box>
    </ModalContainer>
  );
}
