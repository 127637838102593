export function InfoIcon({ color }: { color: string }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.01855 14.1121C11.3323 14.1121 14.0186 11.4258 14.0186 8.11206C14.0186 4.79835 11.3323 2.11206 8.01855 2.11206C4.70485 2.11206 2.01855 4.79835 2.01855 8.11206C2.01855 11.4258 4.70485 14.1121 8.01855 14.1121Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.51855 7.61206H8.01855V11.1121H8.51855"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89355 6.11206C8.30777 6.11206 8.64355 5.77627 8.64355 5.36206C8.64355 4.94785 8.30777 4.61206 7.89355 4.61206C7.47934 4.61206 7.14355 4.94785 7.14355 5.36206C7.14355 5.77627 7.47934 6.11206 7.89355 6.11206Z"
        fill={color}
      />
    </svg>
  );
}
