export function ChevronDown({ isDown = true }: { isDown?: boolean }) {
  return (
    <svg
      className={isDown ? 'rotate-180' : ''}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: isDown ? 'rotate(180deg)' : 'none',
        transition: 'transform 0.3s ease',
      }}
    >
      <path
        d="M5 6.8126L0 1.8126L1.16667 0.645935L5 4.47927L8.83333 0.645935L10 1.8126L5 6.8126Z"
        fill={isDown ? 'gray' : '#F9FAFB'}
      />
    </svg>
  );
}
