import { Box, BoxProps, Skeleton, Typography } from '@mui/material';

import { formatMultiplier } from '../lib/formatMultiplier';
import { useCountdown } from '../providers/CountdownProvider';

export function RefCountdownSection({ ...props }: BoxProps) {
  const { timeLeft, isLoading, multiplier } = useCountdown();

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        height={90}
        width={524}
        sx={{ maxWidth: '100%' }}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} {...props}>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 300,
        }}
      >
        {formatMultiplier(multiplier)} referral points boost expires in
      </Typography>

      <Box display="flex" flex={1} gap={1.5} width="100%">
        <TimerItem value={timeLeft?.days} description="Days" />

        <TimerItem value={timeLeft?.hours} description="hours" />

        <TimerItem value={timeLeft?.minutes} description="minutes" />

        <TimerItem value={timeLeft?.seconds} description="seconds" />
      </Box>
    </Box>
  );
}
function TimerItem({
  value,
  description,
}: {
  value: number | undefined;
  description: string;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      gap={1}
      borderRadius={3}
      sx={{
        backgroundColor: '#182920',
        padding: '8px 14px 10px 14px',
      }}
    >
      <Typography
        color="primary"
        fontSize="20px"
        fontWeight={400}
        lineHeight={1}
      >
        {value ?? 'N/A'}
      </Typography>

      <Typography
        color="text.primary"
        fontSize="12px"
        fontWeight={300}
        lineHeight={1}
      >
        {description}
      </Typography>
    </Box>
  );
}
