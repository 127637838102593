import { Box, Button, Link, Typography } from '@mui/material';

import useGlobalModal from '../../../hooks/useGlobalModal';
import { DangerIcon, WarningImage } from '../../icons';
import { ModalContainer } from '../ModalContainer';

import { CreatorToUnstake } from './UnstakeModal';
import { LEARN_MORE_UNSTAKING } from '../../../constants/externalLinks';

export interface UnstakeWarningModalProps {
  creator: CreatorToUnstake;
}

export function UnstakeWarningModal({ creator }: UnstakeWarningModalProps) {
  const { showModal, hideModal } = useGlobalModal();

  const handleLearnMoreClick = async () => {
    showModal({ modalType: 'UnstakeTermsModal' });
  };

  const handleContinueClick = () => {
    hideModal('UnstakeWarningModal');
    showModal({ modalType: 'UnstakeModal', modalProps: { creator } });
  };

  const warningItems = [
    'If you unstake you will stop earning points!',
    <>
      Click{' '}
      <Link
        href={LEARN_MORE_UNSTAKING}
        target="_blank"
        rel="noopener noreferrer"
        color="text.primary"
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        here
      </Link>{' '}
      to learn more about how points are rewarded for staking.
    </>,
  ];

  return (
    <ModalContainer
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 507px)' } }}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        <Box display="flex" justifyContent="center">
          <WarningImage />
        </Box>
        <Typography fontSize={24} fontWeight={500}>
          IMPORTANT: Unstaking can lead to loss of point rewards!
        </Typography>
        <Box display="flex" flexDirection="column" gap={3}>
          {warningItems.map((item, index) => (
            <Box
              key={index}
              display="flex"
              gap={2}
              alignItems="flex-start"
              sx={{
                svg: { height: '21px' },
              }}
            >
              <DangerIcon />

              <Typography fontSize="14px" fontWeight={300}>
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box display="flex" gap={3} mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLearnMoreClick}
          >
            Learn more
          </Button>

          <Button
            variant="text"
            color="secondary"
            onClick={handleContinueClick}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  );
}
