import { Address } from 'viem';
import { useWriteContract } from 'wagmi';

import { useApproveSimulateContract } from './useApproveSimulateContract';

export function useApproveWriteMethod(
  subscribeRegistryAddress: Address,
  selectedPriceInWei: bigint | undefined,
  onSuccess: (hash: Address) => void,
) {
  const approveSimulationResult = useApproveSimulateContract(
    subscribeRegistryAddress,
    selectedPriceInWei,
  );

  const { writeContract } = useWriteContract({
    mutation: {
      onSuccess: onSuccess,
    },
  });

  const writeApproveIfPossible = async () => {
    if (approveSimulationResult.data?.request) {
      writeContract(approveSimulationResult.data?.request);
    }
  };

  const approveFailureMsg = (
    approveSimulationResult.failureReason?.cause as any
  )?.shortMessage;

  return {
    approveSimulationResult,
    writeApproveIfPossible,
    approveDisabled: !approveSimulationResult.data?.request,
    approveFailureMsg,
  };
}
