import { useTokenPrice } from '../api/useTokenPrice';

export function usePriceInUsd(defaultValue: string = 'N/A') {
  const { data: price, isLoading, isError } = useTokenPrice();

  const convertToUSD = (valueInToken: number): string => {
    if (valueInToken === 0) {
      return '0.00';
    }

    if (price === undefined || price === null || !valueInToken) {
      return defaultValue;
    }

    return (valueInToken * price).toFixed(2);
  };

  return { convertToUSD, isLoading };
}
