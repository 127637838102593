import { useSnackbar } from 'notistack';

export function useCopyToClipboard() {
  const { enqueueSnackbar } = useSnackbar();

  async function copyToClipBoard(textToCopy: string, successText: string) {
    try {
      await navigator.clipboard.writeText(textToCopy);

      enqueueSnackbar(successText, {
        variant: 'success',
        autoHideDuration: 1000,
      });
    } catch (err) {
      console.error(err);
    }
  }

  return { copyToClipBoard };
}
