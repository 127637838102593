import { formatUnits } from 'viem';

import isNullOrUndefined from './isNullOrUndefined';

export default function formatBigintWithDecimals(
  valueInWei: bigint | undefined | null,
  decimals: number,
  maxFractionDigits?: number,
  defaultValue = 'N/A',
) {
  if (isNullOrUndefined(valueInWei)) {
    return defaultValue;
  }

  const valueInEtherStr = formatUnits(valueInWei, decimals);
  if (maxFractionDigits === undefined) {
    return valueInEtherStr;
  }

  const formattedValue = parseFloat(valueInEtherStr).toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: maxFractionDigits,
  });
  return formattedValue;
}
