import { Box, Typography } from '@mui/material';
import { BxpIcon } from '../../icons/bxp';
import { SxpIcon } from '../../icons/sxp';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { QuestDetails } from '../../../api/types/getUserPoints';

export enum QuestType {
  BXP = 'bxp',
  SXP = 'sxp',
}
interface QuestCardProps {
  title: string;
  type?: QuestType;
  details?: QuestDetails;
  isComingSoon?: boolean;
}

export function QuestCard({
  title,
  type = QuestType.SXP,
  details,
  isComingSoon,
}: QuestCardProps) {
  const color = isComingSoon ? '#9B9FA3' : '#38FF93';
  const { showModal } = useGlobalModal();
  const {
    cta,
    description = '',
    example = '',
    headline = '',
    important = '',
    benefits = '',
  } = details || {};

  const openModal = async () => {
    if (!cta) return;
    showModal({
      modalType: 'QuestModal',
      modalProps: {
        title,
        headline,
        description,
        example,
        benefits,
        cta,
        type,
        important,
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        backgroundColor: '#111314',
        border: '1px solid #262829',
        borderRadius: 4,
        p: 5,
        opacity: isComingSoon ? 0.5 : 1,
        filter: isComingSoon ? 'blur(5px)' : 'none',
        '&:hover': {
          backgroundColor: !isComingSoon ? '#262829' : '',
          cursor: !isComingSoon ? 'pointer' : 'not-allowed',
        },
      }}
      onClick={openModal}
    >
      {type === QuestType.BXP ? (
        <BxpIcon stroke={color} />
      ) : (
        <SxpIcon stroke={color} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography color={color} fontSize={'14px'} lineHeight={'17px'}>
          {isComingSoon ? 'Coming soon' : title}
        </Typography>
        <Typography fontSize={'16px'} lineHeight={'16px'} color="#9B9FA3">
          Click to learn more
        </Typography>
      </Box>
    </Box>
  );
}
