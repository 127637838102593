import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';

import { useUserAuthActions } from '../../../hooks/useUserAuthActions';
import { TermsAndPolicyTypography } from '../../TermsAndPolicyTypography';
import { ModalContainer } from '../ModalContainer';

export interface ShareLinkModalProps {}

export function AddEmailModal() {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const { linkEmail } = useUserAuthActions();
  const { enqueueSnackbar } = useSnackbar();

  const handleSetEmail = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (e.target.validity.valid) {
      setEmailError(null);
    } else {
      setEmailError('Invalid email format.');
    }
  };

  const handleAddEmailClick = async () => {
    if (!email) {
      return;
    }

    const { data, error } = await linkEmail(email);

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    } else {
      enqueueSnackbar(`Email with OTP link was sent to this email: ${email}.`, {
        variant: 'success',
      });
    }
  };

  return (
    <ModalContainer
      title="Add your email"
      description="Enter your email to your altcoinist account"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box display="flex" flexDirection="column" gap={10} mt={10}>
        <Box display="flex" gap={2} flexDirection="column">
          <InputLabel
            id="email"
            component={Typography}
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
            sx={{
              color: 'white',
            }}
          >
            Email
          </InputLabel>

          <TextField
            id="email"
            type="email"
            aria-labelledby="email"
            value={email}
            onChange={handleSetEmail}
            fullWidth
            error={!!emailError}
            helperText={emailError ?? ''}
          />
        </Box>

        <Button onClick={handleAddEmailClick} disabled={!email || !!emailError}>
          Add email
        </Button>

        <TermsAndPolicyTypography />
      </Box>
    </ModalContainer>
  );
}
