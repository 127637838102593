import { Box, Button, Typography } from '@mui/material';

import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';
import navigate from '../../../lib/navigate';
import { APP_PATH } from '../../../constants/routings';
import { UserType } from '../../../types/UserType';
import { useSetUserTypeMutation } from '../../../api/users/mutations/useSetUserTypeMutation';
import useCurrentUserData from '../../../hooks/auth/useCurrentUserData';

function Information({ position, text }: { position: number; text: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        color: '#F0F5FA',
        gap: 3,
      }}
    >
      <Typography fontSize="14px">{position}</Typography>
      <Typography fontSize="14px">{text}</Typography>
    </Box>
  );
}

export function BecomeCreatorModal() {
  const { hideModal } = useGlobalModal();
  const { data: userData } = useCurrentUserData();

  const onClose = () => {
    hideModal();
  };

  const setUserTypeMutation = useSetUserTypeMutation();

  const handleSetUserType = () => {
    setUserTypeMutation.mutate(
      { userId: userData.id, userType: UserType.CREATOR },
      {
        onSuccess: () => {
          navigate(APP_PATH);
        },
      },
    );
  };

  return (
    <ModalContainer
      title="Ready to Become a Creator?"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box display="flex" flexDirection="column" pt={3}>
        <Typography color="text.secondary" fontSize="16px">
          As a creator, you can start earning onchain by growing a community and onboarding your followers.
          The next steps to becoming a creator are:
        </Typography>
        <Box display="flex" flexDirection="column" gap={3} pt={6}>
          <Information position={1} text="Set up your pricing." />
          <Information position={2} text="Connect your telegram group." />
        </Box>
        <Button
          variant="contained"
          size="medium"
          onClick={handleSetUserType}
          sx={{ alignSelf: 'start', mt: 10 }}
        >
          Continue
        </Button>
      </Box>
    </ModalContainer>
  );
}
