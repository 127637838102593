import { Box, Button, IconButtonProps } from '@mui/material';

import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { CopyIcon } from '../../icons';

export function CopyClipboardButton({
  textToCopy,
}: {
  textToCopy: string;
} & IconButtonProps) {
  const { copyToClipBoard } = useCopyToClipboard();

  return (
    <Box>
      <Button
        onClick={() => copyToClipBoard(textToCopy, 'Link copied')}
        sx={{
          svg: {
            fill: '#111314',
          },
          '&:hover': {
            stroke: 'none',
          },
        }}
        endIcon={<CopyIcon />}
      >
        Copy
      </Button>
    </Box>
  );
}
