import { useAccount } from 'wagmi';

import useCurrentUserData from './auth/useCurrentUserData';

export enum GlobalConnectErrors {
  NoWalletConnected = 'NoWalletConnected',
  WrongNetwork = 'WrongNetwork',
  WalletNotLinked = 'WalletNotLinked',
  DifferFromLinkedWallet = 'DifferFromLinkedWallet',
  EmailNotLinked = 'EmailNotLinked',
}

export function useGlobalConnectError() {
  const { address, isConnected, chain } = useAccount();
  const { data: userData, isLoading } = useCurrentUserData();

  if (!isConnected) {
    return {
      errorType: GlobalConnectErrors.NoWalletConnected,
    };
  }

  if (!chain) {
    return { errorType: GlobalConnectErrors.WrongNetwork };
  }

  if (!userData?.wallet_address) {
    return { errorType: GlobalConnectErrors.WalletNotLinked };
  }

  if (userData?.wallet_address !== address) {
    return {
      errorType: GlobalConnectErrors.DifferFromLinkedWallet,
    };
  }

  // const emailWarning = !isLoading && !!userData && !userData.email;
  // if (emailWarning) {
  //   return {
  //     errorType: GlobalConnectErrors.EmailNotLinked,
  //   };
  // }

  return { errorType: null };
}
