import { Box, Button, Link, Typography } from '@mui/material';

import { LEARN_MORE_YIELD_BOOST_MECHANISM } from '../../../constants/externalLinks';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';

import { StakeModalProps } from './StakeModal';

export interface StakingLearnMoreModalProps extends StakeModalProps {}

export function StakingLearnMoreModal({ creator }: StakingLearnMoreModalProps) {
  const { showModal, hideModal } = useGlobalModal();

  const handleStakeClick = () => {
    hideModal();
    showModal({ modalType: 'StakeTermsModal', modalProps: { creator } });
  };

  return (
    <ModalContainer
      title="Earn sXP for Staking ETH"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 900px)' } }}
    >
      <>
        <Typography fontSize={20} fontWeight={500} mt={10}>
          You get sXP for every day you stake
        </Typography>

        <Box mt={5} mb={10} maxHeight="240px" sx={{ overflowY: 'auto' }}>
          <Typography
            fontSize={14}
            fontWeight={300}
            color="text.secondary"
            component="div"
          >
            <ul>
              <li>
                ALPHA members earn <strong>DOUBLE sXP</strong> compared to
                having Monthly.
              </li>
              <li>You stake ETH which will be converted to wETH for you.</li>
              <li>You can unstake your wETH anytime.</li>
            </ul>

            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>
                ➔ Monthly Members earn 1.5 sXP/day for 0.01 wETH staked/day.
              </li>
              <li>
                ➔ ALPHA Members earn 3.0 sXP/day for 0.01 wETH staked/day.
              </li>
            </ul>
          </Typography>
        </Box>

        <Button onClick={handleStakeClick}>Start staking</Button>
      </>
    </ModalContainer>
  );
}
