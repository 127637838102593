import { useEffect, useState } from 'react';
import { formatEther } from 'ethers';
import { useSnackbar } from 'notistack';
import { formatUnits } from 'viem';
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

import { Box, Button, Link, Tooltip, Typography } from '@mui/material';

import { LEARN_MORE_ABOUT_SETUP_PRICING_LINK } from '../../../constants/externalLinks';
import useCurrentUserData from '../../../hooks/auth/useCurrentUserData';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { useMainTokenInfo } from '../../../hooks/useMainTokenInfo';
import { usePostHogCapture } from '../../../hooks/usePostHogCapture';
import { useAllSubPricesReadMethod } from '../../../web3/hooks/read/useGetSubPricesReadMethod';
import { useSetSubPricesSimulation } from '../../../web3/hooks/SubscribeRegistry/useSetSubPricesSimulation';
import { TokenAmountInput } from '../Inputs/TokenAmountInput';
import { ModalContainer } from '../ModalContainer';
import { PriceInUsd } from '../PriceInUsd';

function getPriceInToken(monthlyPrice: bigint | null, decimals: number) {
  return monthlyPrice && decimals ? +formatUnits(monthlyPrice, decimals) : 0;
}
export interface SetupPricingModalProps {
  isUpdate?: boolean;
}

export function SetupPricingModal({ isUpdate }: SetupPricingModalProps) {
  const [monthlyPriceInWei, setMonthlyPrice] = useState<bigint | null>(null);
  const [lifetimePriceInWei, setLifetimePrice] = useState<bigint | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { symbol, decimals } = useMainTokenInfo();
  const { data: userData } = useCurrentUserData();
  const { captureSetupPricing } = usePostHogCapture();

  const {
    data: subPrices,
    isSuccess: getPricesSuccess,
    refetch: refetchPrices,
    isLoading,
  } = useAllSubPricesReadMethod(userData?.wallet_address);

  const setSubPricesSimulations = useSetSubPricesSimulation(
    monthlyPriceInWei,
    lifetimePriceInWei,
    userData?.username,
  );

  const { writeContract: setSubPricesWrite, data: setSubPricesTxHash } =
    useWriteContract();

  const { isSuccess: setPricesSuccess } = useWaitForTransactionReceipt({
    hash: setSubPricesTxHash,
    query: {
      enabled: !!setSubPricesTxHash,
    },
  });

  useEffect(() => {
    if (getPricesSuccess && subPrices) {
      setMonthlyPrice(subPrices[0].result ?? null);
      setLifetimePrice(subPrices[1].result ?? null);
    }
  }, [getPricesSuccess, subPrices]);

  // Validate that ALPHA price is greater than monthly price
  useEffect(() => {
    if (
      lifetimePriceInWei &&
      monthlyPriceInWei &&
      lifetimePriceInWei <= monthlyPriceInWei
    ) {
      setErrorMessage('ALPHA price must be greater than the monthly price.');
    } else {
      setErrorMessage(null);
    }
  }, [lifetimePriceInWei, monthlyPriceInWei]);

  const handleSetupPricing = () => {
    if (setSubPricesSimulations.data?.request) {
      setSubPricesWrite(setSubPricesSimulations.data?.request);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const { hideModal } = useGlobalModal();

  useEffect(() => {
    if (setPricesSuccess) {
      enqueueSnackbar('Setup prices completed successfully!', {
        variant: 'success',
      });
      refetchPrices();

      captureSetupPricing({
        monthly_price: formatEther(monthlyPriceInWei!),
        lifetime_price: formatEther(lifetimePriceInWei!),
      });

      hideModal();
    }
  }, [enqueueSnackbar, hideModal, refetchPrices, setPricesSuccess]);

  const setPricesFailureMsg = (
    setSubPricesSimulations.failureReason?.cause as any
  )?.shortMessage;

  const monthlyInToken = getPriceInToken(monthlyPriceInWei, decimals);
  const lifetimeInToken = getPriceInToken(lifetimePriceInWei, decimals);
  const title = isUpdate ? 'Update pricing' : 'Setup pricing';
  const buttonText = isUpdate ? 'Update pricing' : 'Setup pricing';
  const description = isUpdate ? (
    <Typography
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 4 }}
    >
      <Typography component="span">
        • Updated pricing only applies to New Subscribers
      </Typography>
      <Typography component="span">
        • Pricing for existing members will not be affected
      </Typography>
    </Typography>
  ) : (
    "Set how much you'd like to charge for your Monthly and ALPHA Subscription to your community."
  );

  return (
    <ModalContainer
      title={title}
      description={description}
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <>
        <TokenAmountInput
          title="Monthly Subscription"
          symbol={symbol}
          decimals={decimals}
          value={monthlyPriceInWei}
          onValueChanged={(value) => setMonthlyPrice(value)}
          inputProps={{
            disabled: isLoading,
          }}
          pt={10}
          tooltip="Automatically renews for members each month. Members can cancel anytime."
        />
        <PriceInUsd valueInToken={monthlyInToken} pt={2} />

        <TokenAmountInput
          title="ALPHA Subscription"
          symbol={symbol}
          decimals={decimals}
          value={lifetimePriceInWei}
          onValueChanged={(value) => setLifetimePrice(value)}
          inputProps={{
            disabled: isLoading,
          }}
          error={!!errorMessage}
          helperText={errorMessage}
          sx={{ mt: 5 }}
          tooltip="ALPHA Subscription: Gives users access to your community forever."
        />
        <PriceInUsd valueInToken={lifetimeInToken} pt={2} />

        <Tooltip title={setPricesFailureMsg} enterTouchDelay={0}>
          <Box display="flex">
            <Button
              disabled={
                isLoading ||
                !!errorMessage ||
                !monthlyPriceInWei ||
                !lifetimePriceInWei ||
                !setSubPricesSimulations?.data?.request
              }
              onClick={() => handleSetupPricing()}
              sx={{
                alignSelf: 'flex-start',
                mt: 10,
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Tooltip>
        <Typography sx={{ color: '#9B9FA3', fontSize: '16px', pt: 4 }}>
          Click{' '}
          <Link
            target={'_blank'}
            rel="noopener noreferrer"
            href={LEARN_MORE_ABOUT_SETUP_PRICING_LINK}
          >
            here
          </Link>{' '}
          to learn more about fees and your pricing.
        </Typography>
      </>
    </ModalContainer>
  );
}
