import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import { PROFILE_PATH } from '../constants/routings';
import { usePageContext } from '../renderer/usePageContext';

export default function Content({ children }: PropsWithChildren) {
  const pageContext = usePageContext();
  const isProfilePage = pageContext.urlPathname === PROFILE_PATH;

  return (
    <Box
      component="main"
      display="flex"
      flexDirection="column"
      sx={{
        overflowY: 'scroll',
        mt: { xs: 17, md: 8 },
        mx: { xs: 0, md: 10 },
        height: {
          xs: isProfilePage ? 'calc(100vh - 100px)' : '100vh',
          md: 'calc(100vh - 32px)',
        },
        width: { xs: 'auto', md: '1200px' },
      }}
    >
      {children}
    </Box>
  );
}
