import React, { ElementType } from 'react';
import { RecoilRoot } from 'recoil';

import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import GlobalModal from '../components/shared/Modals/GlobalModal';
import { AppLayout } from '../layouts/AppLayout';
import { AuthHandlers } from '../providers/AuthHandlers';
import { NotificationProvider } from '../providers/NotificationProvider';
import { SessionProvider } from '../providers/SessionProvider';
import { Web3Provider } from '../providers/Web3Provider';
import createEmotionCache from '../theme/createEmotionCache';
import theme from '../theme/theme';

import type { PageContext } from './types';
import { PageContextProvider } from './usePageContext';

import './PageShell.css';
export { PageShell };

function PageShell({
  children,
  pageContext,
}: {
  children: React.ReactNode;
  pageContext: PageContext;
}) {
  const cache = createEmotionCache();
  const Layout = pageContext.exports.Layout as ElementType;

  return (
    <React.StrictMode>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <RecoilRoot>
              <NotificationProvider>
                <PageContextProvider pageContext={pageContext}>
                  <Web3Provider>
                    <SessionProvider>
                      <CssBaseline enableColorScheme />
                      <GlobalModal />
                      <AuthHandlers />
                      {Layout ? (
                        <Layout>{children}</Layout>
                      ) : (
                        <AppLayout>{children}</AppLayout>
                      )}
                    </SessionProvider>
                  </Web3Provider>
                </PageContextProvider>
              </NotificationProvider>
            </RecoilRoot>
          </ThemeProvider>
        </CacheProvider>
    </React.StrictMode>
  );
}
