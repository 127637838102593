import { useRecoilState } from 'recoil';

import { modalState, ModalType } from '../recoil/modal';

export default function useGlobalModal() {
  const [modal, setModal] = useRecoilState(modalState);

  const showModal = (type: ModalType) => {
    setModal((curr) => (curr ? [...curr, type] : [type]));
  };

  const hideModal = (modalType?: ModalType['modalType']) => {
    if (!modalType) {
      setModal((modals) =>
        modals?.length ? modals.slice(0, modals.length - 1) : [],
      );
      return;
    }

    setModal(
      (modals) => modals?.filter((m) => m.modalType !== modalType) ?? [],
    );
  };

  return {
    modal,
    setModal,
    showModal,
    hideModal,
  };
}
