import { Address } from 'viem';
import { useSimulateContract } from 'wagmi';

import SubstakingVault from '../../../assets/abi/SubstakingVault';

export function getDepositWethContract(
  vaultContractAddress: Address,
  myAddress: Address,
  amount: bigint,
): {
  address: Address;
  abi: any;
  functionName: 'depositWeth';
  args: [Address, bigint];
} {
  return {
    address: vaultContractAddress,
    abi: SubstakingVault,
    functionName: 'depositWeth',
    args: [myAddress, amount],
  };
}

export function useDepositWethSimulateContract(
  vaultContractAddress: Address | undefined,
  myAddress: Address | undefined,
  amount: bigint | null,
) {
  return useSimulateContract({
    ...getDepositWethContract(vaultContractAddress!, myAddress!, amount!),
    query: {
      enabled: !!vaultContractAddress && !!myAddress && !!amount,
    },
  });
}
