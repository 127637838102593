import { Address, zeroAddress } from 'viem';
import { useSimulateContract } from 'wagmi';

import SubscribeRegistryAbi from '../../../assets/abi/SubscribeRegistry';
import { SubscriptionPlanType } from '../../../types/SubscriptionPlanType';

export function getSubscribeContract(
  creatorAddress: Address,
  subberAddress: Address,
  planSelected: SubscriptionPlanType,
  referredAddress: Address,
) {
  return {
    address: import.meta.env.VITE_SUBSCRIBE_REGISTRY_ADDRESS as Address,
    abi: SubscribeRegistryAbi as any,
    functionName: 'subscribe',
    args: [
      {
        author: creatorAddress,
        subber: subberAddress,
        package: planSelected === SubscriptionPlanType.LIFETIME ? 1 : 0,
        qty: 1n,
        stakeAmount: 0n,
        ref: referredAddress ?? zeroAddress,
      },
    ],
  };
}

export function useSubscribeSumulateContract(
  creatorAddress: Address | undefined,
  subberAddress: Address | undefined,
  planSelected: SubscriptionPlanType,
  referredAddress: Address | null,
  allowance: bigint | undefined,
) {
  return useSimulateContract({
    ...getSubscribeContract(
      creatorAddress!,
      subberAddress!,
      planSelected!,
      referredAddress!,
    ),
    query: {
      enabled:
        !!allowance &&
        !!creatorAddress &&
        !!subberAddress &&
        planSelected !== null,
    },
  });
}
