import { PropsWithChildren } from 'react';

import { Box, Typography } from '@mui/material';

export function ListItem({
  number,
  children,
}: { number: number } & PropsWithChildren) {
  return (
    <Box display="flex" gap={2} alignItems="center">
      <Typography fontSize={{ xs: 12, md: 14 }} fontWeight={500} minWidth={10}>
        {number}
      </Typography>

      <Typography fontSize={{ xs: 12, md: 14 }} fontWeight={300}>
        {children}
      </Typography>
    </Box>
  );
}
