import { useId } from 'react';
import {
  Box,
  BoxProps,
  InputLabel,
  OutlinedInputProps,
  Tooltip,
  Typography,
} from '@mui/material';

import { BigInput } from './BigInput';
import { InfoIcon } from '../../icons/InfoIcon';

export function TokenAmountInput({
  title,
  symbol,
  value,
  decimals,
  onValueChanged,
  inputProps,
  inputVariant = 'outlined',
  tooltip,
  error,
  helperText,
  ...boxProps
}: {
  title?: string;
  symbol?: string;
  value: bigint | null;
  decimals?: number;
  inputVariant?: 'text' | 'outlined';
  onValueChanged?: (inWei: bigint | null) => void;
  inputProps?: OutlinedInputProps;
  tooltip?: string;
  error?: boolean;
  helperText?: string | null;
} & BoxProps) {
  const labelId = useId();

  return (
    <Box display="flex" flexDirection="column" gap={2} {...boxProps}>
      {title && (
        <Box display="flex" gap={1}>
          <InputLabel
            id={labelId}
            component={Typography}
            fontSize={16}
            fontWeight={300}
            sx={{
              color: 'white',
            }}
          >
            {title}
          </InputLabel>
          {tooltip && (
            <Tooltip arrow placement="top" title={tooltip} enterTouchDelay={0}>
              <Box display="flex" alignItems="center">
                <InfoIcon color="#9B9FA3" />
              </Box>
            </Tooltip>
          )}
        </Box>
      )}

      <BigInput
        {...inputProps}
        aria-labelledby={labelId}
        fullWidth
        decimals={decimals}
        value={value}
        symbol={symbol}
        variant={inputVariant}
        onValueChanged={onValueChanged}
        error={error}
      />

      {helperText && (
        <Typography variant="body2" color={error ? 'error' : 'textSecondary'}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
}
