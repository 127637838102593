import { Box, Button, Link, Typography } from '@mui/material';

import { LEARN_MORE_ABOUT_STAKING_IN_COMMUNITY_POOLS_LINK, LEARN_MORE_CREATOR_GAMES } from '../../../constants/externalLinks';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';
import { Text } from '../Text';

export function UnstakeTermsModal() {
  const { hideModal } = useGlobalModal();

  const handleClick = () => {
    hideModal('UnstakeTermsModal');
  };

  return (
    <ModalContainer
      title="Unstaking Now Means Losing Out!"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 960px)' } }}
    >
      <>
        <Typography fontSize={20} fontWeight={500} mt={12}>
          Unstaking your wETH means:
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          maxHeight="350px"
          mt={5}
          mb={10}
          gap={5}
          sx={{ overflowY: 'auto' }}
        >
          <Box>
            <Text>Missed Points:</Text>
            <Text component="li" color="text.primary">
              Unstake now, and you’ll miss out on earning daily sXP.
              The more sXP you have before{' '}
              <Link
              target={'_blank'}
              rel="noopener noreferrer"
              href={LEARN_MORE_CREATOR_GAMES}
              sx={{ textDecoration: 'underline' }}
            >
              The Creator Games,
            </Link>
            {' '}the more you benefit from boosts during the event.
            </Text>
          </Box>

          <Box>
            <Text>Community TVL Decrease:</Text>
            <Text component="li" color="text.primary">
              Unstaking lowers your community’s total value locked (TVL), which can shrink its leaderboard rankings.
            </Text>
          </Box>

          <Box>
            <Text>Overall:</Text>
            <Text component="li" color="text.primary">
              You can unstake anytime, but right now your points are accumulating, and your community is benefiting.
              So, why miss out on more sXP?
            </Text>
          </Box>

          <Typography color="text.primary" fontSize="14px" fontWeight={300}>
            To read more about “Staking In Community Pools” click{' '}
            <Link
              target={'_blank'}
              rel="noopener noreferrer"
              href={LEARN_MORE_ABOUT_STAKING_IN_COMMUNITY_POOLS_LINK}
              sx={{ textDecoration: 'underline' }}
            >
              here
            </Link>
          </Typography>
        </Box>

        <Button onClick={handleClick}>Got it</Button>
      </>
    </ModalContainer>
  );
}
