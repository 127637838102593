import { SupabaseClient } from '@supabase/supabase-js';
import { useMutation } from '@tanstack/react-query';
import useSupabase from '../../supabase';
import { UserType } from '../../../types/UserType';

export function useSetUserTypeMutation() {
  const client = useSupabase();

  const mutationFn = async (data: { userId: string; userType: UserType }) => {
    return addUserType(client, data.userId, data.userType).then(
      (result: any) => result.data,
    );
  };

  return useMutation({ mutationFn });
}

export async function addUserType(
  client: SupabaseClient<any, 'public', any>,
  userId: string,
  userType: UserType,
) {
  return client
    .from('users')
    .update({ user_type: userType, onboarding_complete: false })
    .eq('id', userId)
    .throwOnError()
    .single();
}
