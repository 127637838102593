import React, { useEffect, useState } from 'react';

import { Box, Divider } from '@mui/material';

import { GlobalWarningContainer } from '../components/GlobalWarnings/GlobalWarningContainer';
import { IncorrectWalletWarningContent } from '../components/shared/Modals/IncorrectWalletWarningModal';
import { TelegramWebviewWarning } from '../components/TelegramWebviewWarning';
import {
  APP_PATH,
  CREATORS_PATH,
  PROFILE_PATH,
  SIGNUP_PATH,
} from '../constants/routings';
import {
  GlobalConnectErrors,
  useGlobalConnectError,
} from '../hooks/useWalletConnectError';
import { isTelegramWebview } from '../lib/isTelegramWebview';
import { usePageContext } from '../renderer/usePageContext';

import Content from './Content';
import LeftSidebar from './LeftSidebar';
import MobileHeader from './MobileHeader';
import MobileFooter from './MobileNavigation';
import { CountdownProvider } from '../providers/CountdownProvider';
import { RefCompaignSection } from '../components/RefCompaignSection';

export function AppLayout({ children }: React.PropsWithChildren) {
  const pageContext = usePageContext();
  const { userData } = pageContext;
  const { errorType } = useGlobalConnectError();

  const [usingTelegramWebview, setUsingTelegramWebview] = useState(false);
  useEffect(() => setUsingTelegramWebview(isTelegramWebview()), []);

  const hideMobileFooter =
    pageContext.urlPathname.startsWith(CREATORS_PATH) &&
    pageContext.urlPathname !== CREATORS_PATH;

  if (usingTelegramWebview) {
    return <TelegramWebviewWarning />;
  }

  if (pageContext.urlPathname === SIGNUP_PATH || !userData) {
    return <>{children}</>;
  }

  if (errorType === GlobalConnectErrors.DifferFromLinkedWallet) {
    return (
      <>
        <GlobalWarningContainer />

        <Box
          sx={{
            width: '100vw',
            my: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              p: 10,
              border: '1px solid #2D2F33',
              borderRadius: 3,
              maxWidth: '480px',
            }}
          >
            <IncorrectWalletWarningContent />
          </Box>
        </Box>
      </>
    );
  }

  if (
    pageContext.urlPathname.startsWith(CREATORS_PATH) &&
    pageContext.urlPathname.endsWith('/subscribe')
  ) {
    return (
      <>
        <GlobalWarningContainer />
        {children}
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <GlobalWarningContainer />
      <Box
        display="flex"
        mx="auto"
        maxWidth="1700px"
        minWidth={{ xs: '100vw', md: 'auto' }}
      >
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          <LeftSidebar />
        </Box>

        <Box
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
          }}
        >
          <MobileHeader />
          {!hideMobileFooter && <MobileFooter />}
        </Box>

        <Divider
          orientation="vertical"
          sx={{
            height: 'auto',
            ml: 6,
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        />

        <Content>{children}</Content>
      </Box>
    </Box>
  );
}
