import { Box, Typography } from '@mui/material';

import { SuccessSmallIcon, WarningIconSmall } from '../icons';
import { InfoIcon } from '../icons/InfoIcon';

export type FeedbackType = 'success' | 'error' | 'warning' | 'info';

export interface FeedbackInfo {
  type: FeedbackType;
  title: React.ReactNode;
  body?: string;
}

interface FeedbackBannerProps extends FeedbackInfo {}

export function FeedbackBanner({ type, title, body }: FeedbackBannerProps) {
  return (
    <Box
      sx={{
        padding: 3,
        display: 'flex',
        gap: 2,
        bgcolor: getBgColoroByType(type),
        borderRadius: '10px',
      }}
    >
      <Box pt="2px">
        {(type === 'error' || type === 'info') && <InfoIcon color="white" />}
        {type === 'warning' && <WarningIconSmall color="white" />}
        {type === 'success' && <SuccessSmallIcon />}
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="body2">{title}</Typography>

        {body && (
          <Typography fontSize="12px" color="#E5E5E5">
            {body}
          </Typography>
        )}
      </Box>
    </Box>
  );

  function getBgColoroByType(type: FeedbackType) {
    return type === 'error'
      ? '#991B1B'
      : type === 'warning'
      ? '#7A1616'
      : type === 'success'
      ? '#182920'
      : type === 'info'
      ? '#383B3D'
      : 'black';
  }
}
