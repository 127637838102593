import { BigNumberInput } from 'big-number-input';

import { OutlinedInput, OutlinedInputProps, Typography } from '@mui/material';

export type BigIntProps = OutlinedInputProps & {
  value: bigint | null;
  symbol?: string;
  decimals?: number;
  variant?: 'text' | 'outlined';
  onValueChanged?: (inWei: bigint | null) => void;
};

export function BigInput({
  value,
  onValueChanged,
  decimals = 18,
  symbol,
  variant = 'outlined',
  ...inputProps
}: BigIntProps) {
  const onChange = (newValue: string): void => {
    const bigIntValue = newValue != null ? BigInt(newValue) : null;

    onValueChanged?.(bigIntValue);
  };

  return (
    <BigNumberInput
      decimals={decimals}
      onChange={onChange}
      value={value || value === 0n ? value.toString() : ''}
      min="0"
      placeholder="Enter amount"
      renderInput={(props) => (
        <OutlinedInput
          name="value"
          size="medium"
          endAdornment={
            <Typography fontSize={16} fontWeight={300} color="#ACAFB3">
              {symbol}
            </Typography>
          }
          inputProps={{
            ...props,
          }}
          {...inputProps}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#3B3D40',
              ...(variant === 'text' && { border: 'none' }),
            },
            '.MuiOutlinedInput-input': {
              height: 20,
              padding: '16px 20px 16px 0',
            },
            fontSize: variant === 'text' ? '48px' : '16px',
            fontWeight: variant === 'text' ? 500 : 300,
            height: 52,
            px: variant === 'text' ? 0 : 5,
            ...inputProps.sx,
          }}
        />
      )}
    />
  );
}
