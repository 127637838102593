import { useEffect } from 'react';

import { useUserAvatarUpdateMutation } from '../api/users/mutations/useUserAvatarUpdateMutation';
import useTwitterAvatarUrl from '../hooks/auth/useCorrectTwitterAvatar';
import useCurrentUserData from '../hooks/auth/useCurrentUserData';

export function AuthHandlers() {
  useTwitterSeesionHandler();

  return <></>;
}

function useTwitterSeesionHandler() {
  const userAvatarUpdate = useUserAvatarUpdateMutation();
  const { data: userData } = useCurrentUserData();
  const newAvatarUrl = useTwitterAvatarUrl();

  useEffect(() => {
    if (!userData || !newAvatarUrl || userData.avatar === newAvatarUrl) {
      return;
    }
    const postData = {
      username: userData.username,
      avatar: newAvatarUrl,
    };
    userAvatarUpdate.mutate(postData, {
      onSuccess: () => {
        // window.location.reload();
      },
    });
  }, [newAvatarUrl, userData]);
}
