import { Box, Divider, Typography } from '@mui/material';

import { SubscriptionPlanType } from '../../../../types/SubscriptionPlanType';
import { SelectionPlanSidePanel } from '../../../SelectionPlanSidePanel';
import { SubscriptionPanel } from '../../../SubscriptionPanel';
import { ModalContainer } from '../../ModalContainer';

export interface SwitchPlanModalProps {
  username: string;
}

export function SwitchPlanModal({ username }: SwitchPlanModalProps) {
  const planSelected = SubscriptionPlanType.LIFETIME;

  return (
    <ModalContainer
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 960px)' } }}
    >
      <Box display="flex" m={-10}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mx={14}
          my={10}
          gap={10}
          flex={1}
        >
          <Typography variant="h3" fontWeight={500}>
            Review your subscription
          </Typography>

          <SubscriptionPanel
            planSelected={planSelected}
            username={username}
            affilateWalletParam={null}
          />
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mx={14}
          my={10}
          gap={10}
          flex={1}
        >
          <Typography variant="h3" fontWeight={500}>
            You are switching to
          </Typography>

          <SelectionPlanSidePanel
            planSelected={planSelected}
            username={username}
          />
        </Box>
      </Box>
    </ModalContainer>
  );
}
