import createCache from '@emotion/cache';

// export default function createEmotionCache() {
//   return createCache({ key: 'css',  });
// }

const isBrowser = typeof document !== 'undefined';

// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that Material UI styles are loaded first.
// It allows developers to easily override Material UI styles with other styling solutions, like CSS modules.
export default function createEmotionCache() {
  let insertionPoint;

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector(
      'meta[name="emotion-insertion-point"]',
    );
    insertionPoint = (emotionInsertionPoint ?? undefined) as HTMLElement;
  }

  return createCache({ key: 'mui-style', insertionPoint });
}
