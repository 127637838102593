import { Address } from 'viem';
import { useReadContract } from 'wagmi';

import SubstakingFactory from '../../../assets/abi/SubstakingFactory';

export function useGetCreatorVaultByAddress(
  creatorAddress: Address | undefined,
) {
  return useReadContract({
    address: import.meta.env.VITE_SUBSTAKING_FACTORY_ADDRESS as Address,
    abi: SubstakingFactory,
    functionName: 'vaults',
    args: [creatorAddress],
    query: {
      enabled: !!creatorAddress,
    },
  });
}
