import { useEffect, useState } from 'react';

export function useIsAppReady() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return { isReady: loaded };
}
