export const LANDING_PATH = '/';

export const APP_PATH = '/app';

export const SIGNUP_PATH = '/signup';

export const HOME_PATH = APP_PATH;

export const POINTS_PATH = `${APP_PATH}/points`;

export const CREATORS_PATH = `${APP_PATH}/creators`;

export const FINANCE_PATH = `${APP_PATH}/finance`;

export const PROFILE_PATH = `${APP_PATH}/profile`;

export const GET_CREATOR_PATH = (creatorUsername: string) =>
  `${CREATORS_PATH}/${creatorUsername}`;

export const EDIT_PROFILE_PATH = `${PROFILE_PATH}/edit`;
