import { Box, Button, Link, Typography, useTheme } from '@mui/material';

import { TelegramWebviewWarningIcon } from './icons';

export function TelegramWebviewWarning() {
  const theme = useTheme();
  const url = import.meta.env.VITE_PUBLIC_SITE_URL;

  const handleOpenInBrowser = () => {
    window.open(url, '_system', "location=yes");
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: 3,
        px: 4,
        pb: 4,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box sx={{ alignSelf: 'center' }}>
        <TelegramWebviewWarningIcon />
      </Box>
      <Typography variant="h2" sx={{ color: theme.palette.text.primary }}>
        You are using Telegram&rsquo;s in-app browser!
      </Typography>
      <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
        Some features may not be supported here.
        Please open Altcoinist on your default browser, such as Chrome, Safari, or Firefox, for the full experience.
      </Typography>
      {/* <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleOpenInBrowser}
      >
        Open in Browser
      </Button> 
      <Typography variant="body2" sx={{ mt: 1, color: theme.palette.text.secondary }}>
        If the button above does not work,{' '}
        <Link
          href={import.meta.env.VITE_PUBLIC_SITE_URL}
          target="_system"
          rel="noopener noreferrer"
          sx={{ color: theme.palette.primary.main, textDecoration: 'underline' }}
        >
          long press here to open in your favorite browser
        </Link>.
      </Typography> */}
    </Box>
  );
}

export default TelegramWebviewWarning;



