import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Address } from 'viem';
import { useWriteContract } from 'wagmi';

import { Box, Button, Divider, Link, Tooltip, Typography } from '@mui/material';

import { LEARN_MORE_UNSTAKING } from '../../../constants/externalLinks';
import { ZERO_ADDRESS } from '../../../constants/web3';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { usePriceInUsd } from '../../../hooks/usePriceInUsd';
import { useUnstakeTokenInfo } from '../../../hooks/useUnstakeTokenInfo';
import { formatBigint } from '../../../lib/formatBigInt';
import { useWalletData } from '../../../pages/app/creators/@id/subscribe/useWalletData';
import { ICreator } from '../../../types/ICreator';
import {
  FeedbackDetails,
  getErrorDetails,
} from '../../../web3/getErrorDetails';
import { useGetCreatorVaultByAddress } from '../../../web3/hooks/SubstakingFactory/useGetVaultByAddress';
import { useGetWethDepositReadContract } from '../../../web3/hooks/SubstakingVault/useGetWethDepositReadContract';
import { useWithdrawWethSimulateContract } from '../../../web3/hooks/SubstakingVault/useWithdrawWethSimulateContract';
import { FeedbackBanner } from '../FeedbackBanner';
import { TokenAmountInput } from '../Inputs/TokenAmountInput';
import { ModalContainer } from '../ModalContainer';

export type CreatorToUnstake = Pick<ICreator, 'wallet_address'>;

export interface UnstakeModalProps {
  creator: CreatorToUnstake;
}

export function UnstakeModal({ creator }: UnstakeModalProps) {
  const { hideModal } = useGlobalModal();
  const [valueToUnstakeInWei, setValueToUnstakeInWei] = useState<bigint | null>(
    0n,
  );

  const { symbol, decimals } = useUnstakeTokenInfo();
  const { address } = useWalletData();

  const valueInEth = formatBigint(valueToUnstakeInWei, decimals);

  const { data: creatorVaultAddress } = useGetCreatorVaultByAddress(
    creator?.wallet_address,
  );

  const { data: stakedAmount } = useGetWethDepositReadContract(
    creatorVaultAddress as Address,
    address,
  );

  const stakedAmountInWei = stakedAmount as bigint;
  const { convertToUSD } = usePriceInUsd('');

  const stakedAmountInEthStr = formatBigint(stakedAmountInWei, decimals);

  const unstakedAmountInUsdStr = valueInEth ? convertToUSD(+valueInEth) : 'N/A';

  const simulateWithdrawWethResult = useWithdrawWethSimulateContract(
    creatorVaultAddress as Address,
    valueToUnstakeInWei,
  );

  const { enqueueSnackbar } = useSnackbar();

  const onUnstakeSuccess = (hash: string) => {
    enqueueSnackbar(`Unstaked successfully! TX hash: ${hash}`, {
      variant: 'success',
    });

    window.location.reload();
  };

  const { writeContract: writeStake } = useWriteContract({
    mutation: {
      onSuccess: onUnstakeSuccess,
    },
  });

  const handleUntake = async () => {
    if (simulateWithdrawWethResult.data?.request) {
      writeStake(simulateWithdrawWethResult.data?.request);
    }
  };

  const onCancelClick = () => {
    hideModal();
  };

  const setMaxAvailableValue = () => {
    setValueToUnstakeInWei((stakedAmountInWei as bigint) ?? null);
  };

  const hasCorrectVaultAddress =
    creatorVaultAddress && creatorVaultAddress !== ZERO_ADDRESS;

  const unstakeDisabled =
    !simulateWithdrawWethResult?.data?.request || !hasCorrectVaultAddress;

  const stakeErrorDetails = (() => {
    switch (true) {
      case !hasCorrectVaultAddress:
        return UNSTAKE_ERRORS_MAP.INCORRECT_VAULT_ADDRESS;
    }
    return getErrorDetails(
      simulateWithdrawWethResult.failureReason,
      UNSTAKE_ERRORS_MAP,
    );
  })();

  return (
    <ModalContainer
      title="Unstake"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box mt={10}>
        <TokenAmountInput
          title="Enter amount"
          decimals={decimals}
          value={valueToUnstakeInWei}
          onValueChanged={setValueToUnstakeInWei}
          inputVariant="text"
          inputProps={{
            disabled: false,
          }}
        />

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography color="text.secondary" fontSize={16} fontWeight={300}>
            {symbol}
          </Typography>

          <Typography>≈ {unstakedAmountInUsdStr} USD</Typography>
        </Box>

        <Divider sx={{ my: 6 }} />

        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography color="text.secondary" fontSize={16} fontWeight={300}>
              Available amount
            </Typography>

            <Typography color="text.primary" fontSize={16} fontWeight={300}>
              {stakedAmountInEthStr} {symbol}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            sx={{
              ml: 'auto',
            }}
            onClick={setMaxAvailableValue}
          >
            Max
          </Button>
        </Box>

        <Divider sx={{ my: 6 }} />

        <Box display="flex" gap={2} justifyContent="space-between" my={2}>
          <Typography color="text.primary" fontSize={16} fontWeight={300}>
            You`re receiving
          </Typography>

          <Typography color="text.primary" fontSize={32} fontWeight={500}>
            {valueInEth} {symbol}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={4}>
          <FeedbackBanner
            type="warning"
            title={
              <>
                Unstaking will decrease your sXP rewards.{' '}
                <Link
                  href = {LEARN_MORE_UNSTAKING}
                  color="inherit"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Click here
                </Link>{' '}
                to learn more about how staking earns you sXP.
              </>
            }
          />
          {stakeErrorDetails && (
            <Tooltip title={stakeErrorDetails.body} enterTouchDelay={0}>
              <FeedbackBanner
                type={stakeErrorDetails.type}
                title={stakeErrorDetails.title}
                body={stakeErrorDetails.body}
              />
            </Tooltip>
          )}
        </Box>

        <Box display="flex" mt={6} gap={3}>
          <Button
            disabled={unstakeDisabled}
            color="error"
            onClick={handleUntake}
          >
            Unstake
          </Button>

          <Button variant="text" color="secondary" onClick={onCancelClick}>
            Cancel
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  );
}

export const UNSTAKE_ERRORS_MAP: Record<string, FeedbackDetails> = {
  IB: {
    title: 'Unstaking amount should be not more than staked amount.',
    body: 'Please, change unstaking amount.',
    type: 'error',
  },
  INCORRECT_VAULT_ADDRESS: {
    title: "This creator doesn't have correct vault address",
    body: 'Creator should create vault by set up subscriptions prices',
    type: 'error',
  },
};
