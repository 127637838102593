import { Address } from 'viem';
import { useWriteContract } from 'wagmi';

import { SubscriptionPlanType } from '../../../types/SubscriptionPlanType';

import { useSubscribeSumulateContract } from './useSubscribeSumulateContract';

export function useSubscribeWriteMethod(
  creatorAddress: Address,
  subberAddress: Address,
  planSelected: SubscriptionPlanType,
  referredAddress: Address | null,
  allowance: bigint | undefined,
  onSuccess: any,
) {
  const simulateResponse = useSubscribeSumulateContract(
    creatorAddress,
    subberAddress,
    planSelected,
    referredAddress,
    allowance,
  );

  const { writeContract: subscribeWrite } = useWriteContract({
    mutation: {
      onSuccess: onSuccess,
    },
  });

  const writeSubscribeIfPossible = async () => {
    if (simulateResponse.data?.request) {
      subscribeWrite(simulateResponse.data?.request);
    }
  };

  const subscribeFailureMsg = (simulateResponse.failureReason?.cause as any)
    ?.shortMessage as string | undefined;

  return {
    subscribeSimulateResponse: simulateResponse,
    writeSubscribeIfPossible,
    subscribeDisabled: !simulateResponse.data?.request,
    subscribeFailureMsg,
  };
}
