import { Link, Typography, TypographyProps } from '@mui/material';

import {
  LEARN_MORE_ALTCOINIST_TERMS_OF_USE,
  LEARN_MORE_PRIVACY_POLICY,
} from '../constants/externalLinks';

export function TermsAndPolicyTypography({ ...props }: TypographyProps) {
  return (
    <Typography
      fontSize={12}
      fontWeight={300}
      color="text.secondary"
      {...props}
    >
      By continuing, you accept Altcoinist’s{' '}
      <Link
        href={LEARN_MORE_ALTCOINIST_TERMS_OF_USE}
        color="text.primary"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link
        href={LEARN_MORE_PRIVACY_POLICY}
        color="text.primary"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        Privacy Policy
      </Link>
      .
    </Typography>
  );
}
