import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

export function GlobalWarningWrapper({ children }: PropsWithChildren) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      position="sticky"
      top={0}
      flexShrink={0}
      sx={{ backgroundColor: '#7A1616', zIndex: 10000 }}
      p={2}
    >
      {children}
    </Box>
  );
}
