export default [
  {
    type: 'constructor',
    inputs: [{ name: '_altt', type: 'address', internalType: 'address' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'acceptOwnership',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'altt',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'contract ALTT' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'canStake',
    inputs: [
      { name: 'author', type: 'address', internalType: 'address' },
      { name: 'account', type: 'address', internalType: 'address' },
    ],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getRenewPrice',
    inputs: [
      { name: 'author', type: 'address', internalType: 'address' },
      { name: 'subber', type: 'address', internalType: 'address' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getSubDetails',
    inputs: [
      { name: 'author', type: 'address', internalType: 'address' },
      { name: 'follower', type: 'address', internalType: 'address' },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getSubPrice',
    inputs: [
      { name: 'author', type: 'address', internalType: 'address' },
      {
        name: 'package',
        type: 'uint8',
        internalType: 'enum SubscribeRegistry.packages',
      },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'isTrustedForwarder',
    inputs: [{ name: 'forwarder', type: 'address', internalType: 'address' }],
    outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'owner',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'pendingOwner',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'renounceOwnership',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setFactories',
    inputs: [
      {
        name: '_stakingFactory',
        type: 'address',
        internalType: 'address',
      },
      {
        name: '_authorTokenFactory',
        type: 'address',
        internalType: 'address',
      },
      { name: '_notifier', type: 'address', internalType: 'address' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'setSubPrice',
    inputs: [
      { name: 'monthly', type: 'uint256', internalType: 'uint256' },
      { name: 'lifetime', type: 'uint256', internalType: 'uint256' },
      { name: 'uname', type: 'string', internalType: 'string' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'subPrices',
    inputs: [
      { name: '', type: 'address', internalType: 'address' },
      {
        name: '',
        type: 'uint8',
        internalType: 'enum SubscribeRegistry.packages',
      },
    ],
    outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'subscribe',
    inputs: [
      {
        name: 'params',
        type: 'tuple',
        internalType: 'struct SubscribeRegistry.SubscribeParams',
        components: [
          { name: 'author', type: 'address', internalType: 'address' },
          { name: 'subber', type: 'address', internalType: 'address' },
          {
            name: 'package',
            type: 'uint8',
            internalType: 'enum SubscribeRegistry.packages',
          },
          { name: 'qty', type: 'uint256', internalType: 'uint256' },
          {
            name: 'stakeAmount',
            type: 'uint256',
            internalType: 'uint256',
          },
          { name: 'ref', type: 'address', internalType: 'address' },
        ],
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'transferOwnership',
    inputs: [{ name: 'newOwner', type: 'address', internalType: 'address' }],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'trustedForwarder',
    inputs: [],
    outputs: [{ name: '', type: 'address', internalType: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'unsubscribe',
    inputs: [
      { name: 'author', type: 'address', internalType: 'address' },
      { name: 'subber', type: 'address', internalType: 'address' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    name: 'OwnershipTransferStarted',
    inputs: [
      {
        name: 'previousOwner',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'newOwner',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'OwnershipTransferred',
    inputs: [
      {
        name: 'previousOwner',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'newOwner',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'StakingNFTMinted',
    inputs: [
      {
        name: 'author',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'staker',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Subscribed',
    inputs: [
      {
        name: 'subber',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'author',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'package',
        type: 'uint8',
        indexed: false,
        internalType: 'enum SubscribeRegistry.packages',
      },
      {
        name: 'expiry',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'price',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256',
      },
      {
        name: 'ref',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'Unsubscribed',
    inputs: [
      {
        name: 'author',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'subber',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'error',
    name: 'AddressEmptyCode',
    inputs: [{ name: 'target', type: 'address', internalType: 'address' }],
  },
  {
    type: 'error',
    name: 'AddressInsufficientBalance',
    inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
  },
  { type: 'error', name: 'FailedInnerCall', inputs: [] },
  {
    type: 'error',
    name: 'OwnableInvalidOwner',
    inputs: [{ name: 'owner', type: 'address', internalType: 'address' }],
  },
  {
    type: 'error',
    name: 'OwnableUnauthorizedAccount',
    inputs: [{ name: 'account', type: 'address', internalType: 'address' }],
  },
  { type: 'error', name: 'ReentrancyGuardReentrantCall', inputs: [] },
  {
    type: 'error',
    name: 'SafeERC20FailedOperation',
    inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
  },
];
