import { useSnackbar } from 'notistack';
import { Address } from 'viem';

import { Box, Button, Tooltip, Typography } from '@mui/material';

import useGlobalModal from '../../../hooks/useGlobalModal';
import {
  FeedbackDetails,
  getErrorDetails,
} from '../../../web3/getErrorDetails';
import { useUnubscribeWriteMethod } from '../../../web3/hooks/SubscribeRegistry/useUnsubscribeWriteMethod';
import { DangerIcon, WarningImage } from '../../icons';
import { FeedbackBanner } from '../FeedbackBanner';
import { ModalContainer } from '../ModalContainer';
import { useUnsubscribe } from '../../../api/users/mutations/useUnsubMutation';
export interface UnsubscribeModalProps {
  creatorUsername: string;
  creatorWalletAddress: Address;
  subberWalletAddress: Address;
}

export function UnsubscribeModal({
  creatorUsername,
  creatorWalletAddress,
  subberWalletAddress,
}: UnsubscribeModalProps) {
  const { hideModal } = useGlobalModal();

  const { enqueueSnackbar } = useSnackbar();
  const unsubMutation = useUnsubscribe();
  const onUnubscribeSuccess = () => {
    enqueueSnackbar(`Unsubscribed successfully!`, {
      variant: 'success',
    });

    window.location.reload();
  };

  //const { unsubscribeDisabled, writeUnsubscribeIfPossible, failureReason } =
  //  useUnubscribeWriteMethod(
  //    creatorWalletAddress,
  //    subberWalletAddress,
  //    onUnubscribeSuccess,
  //  );
  const unsubscribeDisabled = false;
  const handleUnsubscribe = async () => {
    if (unsubscribeDisabled) {
      return;
    }

    // writeUnsubscribeIfPossible();
    const mutRet = await unsubMutation.mutateAsync({
      followed: creatorWalletAddress,
    });
    onUnubscribeSuccess();
  };

  const onClose = () => {
    hideModal();
  };

  return (
    <ModalContainer>
      <Box
        sx={{
          display: 'flex',
          gap: 6,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <WarningImage />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h4" gap={2}>
            Are you sure want unsubscribe {creatorUsername}?
          </Typography>

          <Typography>If you unsubscribe:</Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" gap={2} alignItems="center">
            <DangerIcon />

            <Typography fontSize="12px" fontWeight={300}>
              You loose your community access
            </Typography>
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <DangerIcon />

            <Typography fontSize="12px" fontWeight={300}>
              You loose your staking rights into the community pool
            </Typography>
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <DangerIcon />

            <Typography fontSize="12px" fontWeight={300}>
              You loose your yield boost you have accrued since staking
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleUnsubscribe}
            disabled={unsubscribeDisabled}
          >
            Unsubscribe
          </Button>

          <Button variant="text" color="secondary" onClick={onClose}>
            Go back
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  );
}

export const UNSUBSCRIBE_ERRORS_MAP: Record<string, FeedbackDetails> = {
  PD: {
    title: 'Wallet of subber is incorrect.',
    body: 'Please, try send transaction with correct wallet address.',
    type: 'error',
  },
  ALS: {
    title: 'Not possible to unsubscribe with this subscription.',
    body: 'You are not subscribed to this creator or subscribed with ALPHA plan.',
    type: 'error',
  },
};
