import { Address, erc20Abi } from 'viem';
import { useSimulateContract } from 'wagmi';

export function getApproveContract(
  subscribeRegistryAddress: Address,
  selectedPriceInWei: bigint,
): {
  address: Address;
  abi: typeof erc20Abi;
  functionName: 'approve';
  args: [Address, bigint];
} {
  return {
    address: import.meta.env.VITE_TOKEN_CONTRACT_ADDRESS as Address,
    abi: erc20Abi,
    functionName: 'approve',
    args: [subscribeRegistryAddress, selectedPriceInWei],
  };
}

export function useApproveSimulateContract(
  contractSpenderAddress: Address,
  selectedPriceInWei: bigint | undefined,
) {
  return useSimulateContract({
    ...getApproveContract(contractSpenderAddress, selectedPriceInWei as bigint),
    query: {
      enabled: !!contractSpenderAddress && !!selectedPriceInWei,
    },
  });
}
