import { Box, Button, Link, Typography } from '@mui/material';

import { LEARN_MORE_ALTCOINIST_TERMS_OF_USE } from '../../../constants/externalLinks';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';

export function UsCitizenModal() {
  const { hideModal } = useGlobalModal();

  const onClose = () => {
    hideModal();
  };

  return (
    <ModalContainer
      title="Altcoinist Terms of Use"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box display="flex" flexDirection="column" pt={3}>
        <Typography
          color="text.secondary"
          fontSize="16px"
          whiteSpace="pre-line"
        >
          {`There are regions where becoming a creator is not supported by
          Altcoinist.

          Click `}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={LEARN_MORE_ALTCOINIST_TERMS_OF_USE}
            color="text.primary"
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            here
          </Link>
          {' to read more about Altcoinist’s Terms of Use.'}
        </Typography>

        <Button
          variant="contained"
          size="medium"
          onClick={onClose}
          sx={{ alignSelf: 'start', mt: 10 }}
        >
          Got it
        </Button>
      </Box>
    </ModalContainer>
  );
}
