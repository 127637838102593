import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { useTokenPrice } from '../../api/useTokenPrice';
import getFiatCurrencyName from '../../lib/getFiatCurrencyName';

const fontStyle = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '125%',
};

export function PriceInUsd({
  valueInToken,
  showCurrencyName = true,
  ...props
}: { valueInToken: number; showCurrencyName?: boolean } & TypographyProps) {
  const { data: price, isLoading, error } = useTokenPrice();

  const result =
    price !== null && price !== undefined ? price * valueInToken : null;

  if (isLoading) {
    return (
      <Typography color="text.secondary" sx={{ ...fontStyle }} {...props}>
        Loading...
      </Typography>
    );
  }

  if (error) {
    return (
      <Tooltip title={error.message} enterTouchDelay={0}>
        <Typography color="error" sx={{ ...fontStyle }} {...props}>
          Failed to fetch price
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Typography color="text.secondary" sx={{ ...fontStyle }} {...props}>
      {showCurrencyName ? '≈' : '$'}
      {result?.toFixed(2) ?? '-'}
      {showCurrencyName && ` ${getFiatCurrencyName()}`}
    </Typography>
  );
}
