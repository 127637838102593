import { SupabaseClient } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

import useSupabase from '../supabase';

export function useCreatorByUsernameQuery(username: string | undefined) {
  const client = useSupabase();
  const queryKey = ['creator', username];

  const queryFn = async () => {
    return getUserByUsername(client, username ?? '').then(
      (result: any) => result.data,
    );
  };

  return useQuery({ queryKey, queryFn, enabled: !!username });
}

export function getUserByUsername(
  client: SupabaseClient<any, 'public', any>,
  username: string,
) {
  return client.from('users_view').select().eq('username', username).single();
}
